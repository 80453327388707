import { Component } from '@angular/core';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent {
    appSlideMenuState: string = 'out';

    WidthChangedHandler(width: any) {
        this.appSlideMenuState = width;
    }


}
