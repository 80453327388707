import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "decamel" })
export class DecamelPipe implements PipeTransform {
    transform(value: any, ...args: any[]) {
        if (value && (typeof value === 'string' || value instanceof String)) {
            return value.replace(/_/g, ' ')
                .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space before capital letters
                .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize each word
        }
        return value;
    }
}