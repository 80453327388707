import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Map, AuthenticationType, control, ControlPosition, HtmlMarker } from 'azure-maps-control';
import { environment } from 'src/environments/environment';
import { LatAndLong, ShowMap } from '../map/latandlong';
import { coord3857To4326, coord4326To3857 } from '../../utilities/convert-epsg';
import { AdminService } from 'src/app/modules/admin/services/admin.service';
import { getColorByRootName } from 'src/app/theme/colors';

@Component({
    selector: 'map-component',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnChanges {

    key: string = environment.azureMapSubscriptionKey;
    map!: Map;
    @Input() showMap = '';
    @Input() incomingLatAndLong: LatAndLong;
    @Output() outgoingLatAndLong = new EventEmitter<LatAndLong>();
    @Output() outgoingAddress = new EventEmitter<string>();

    constructor(private adminService: AdminService) { }

    ngOnInit() {
        // Initialise the map
        this.map = new Map('map-container', {
            authOptions: {
                authType: AuthenticationType.subscriptionKey,
                subscriptionKey: this.key
            },
            zoom: 12,
            showLogo: false,
            showFeedbackLink: false,
            dragRotateInteraction: false,
        });

        this.map.events.add('error', (e) => {
            console.log('Error loading map resource : ', e.error)
        })
        this.mapResourceIsLoaded();
    }

    mapResourceIsLoaded() {
        // Event that triggers when the map resources are ready.
        this.map.events.add('ready', () => {
            // Zoom - Adds the + and - zoom controls
            // Compass - Makes it possible to rotake map
            // Pitch - Makes it possible to pitch/tilt the map
            // Style - Adds other typs of layouts for the maps
            this.map.controls.add([
                new control.ZoomControl(),
                new control.CompassControl(),
                new control.PitchControl(),
                new control.StyleControl()
            ], {
                position: ControlPosition.TopRight
            })
        });
    }

    //Create a HTML marker and add it to the map
    initialiseMarker(markers: LatAndLong) {
        this.map.markers.clear();
        let htmlMarker = new HtmlMarker({
            color: getColorByRootName("--brill-os-pink-color"),
            position: markers ? [markers.lng, markers.lat] : null,
            draggable: markers ? markers.draggable : false
        });

        // Append event to drag marker
        this.map.events.add('dragend', htmlMarker, (event) => {
            var pos = htmlMarker.getOptions().position;
            this.adminService.getAddressByLatAndLon(`${pos[1]}, ${pos[0]}`).then(data => {
                this.outgoingAddress.emit(data.addresses[0].address.freeformAddress)
            })
            this.outgoingLatAndLong.emit(coord3857To4326({ lat: pos[1], lng: pos[0], draggable: true }));
        });
        if (markers) {
            this.map.markers.add(htmlMarker);
        }
        // Animated Zoom to location
        this.map.setCamera({
            center: markers ? [markers.lng, markers.lat] : null,
            type: 'jump',
            zoom: 12
        });

        this.resizeMap();
    }
    // This is used to resize the tiles, theres a bug here, the tiles don't load up altogether.
    resizeMap() {
        setTimeout(() => {
            this.map.resize()
        }, 0);
    }

    clickEventHandler = (e) => {
        this.initialiseMarker({ lat: e.position[1], lng: e.position[0], draggable: true });
        this.outgoingLatAndLong.emit(coord3857To4326({ lat: e.position[1], lng: e.position[0], draggable: true }));
    };

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['incomingLatAndLong'] && changes['incomingLatAndLong'].currentValue) {
            const convertedLatAndLng = this.showMap === ShowMap.noLocation ? this.incomingLatAndLong : coord4326To3857(this.incomingLatAndLong);
            this.initialiseMarker(convertedLatAndLng);
            if (this.showMap === ShowMap.noLocation) {
                this.map.events.addOnce('click', this.clickEventHandler);
                this.map.markers.clear();
                this.map.setCamera({
                    zoom: 4,
                });
            }
        }
    }
}