import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class GlobalUrl {
    private _baseUrl: string = environment.mulanApi;
    private _badmintonUrl: string = environment.badmintonApi;

    get baseUrl(): string {
        return this._baseUrl
    }

    set baseUrl(value: string) {
        this._baseUrl = value;
    }

    get badmintonUrl(): string {
        return this._badmintonUrl
    }

    set badmintonUrl(value: string) {
        this._badmintonUrl = value;
    }

}