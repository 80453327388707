import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Organisation } from 'src/app/modules/admin/entities/api/organisation';
import { GatewayManagementService } from 'src/app/modules/gateway-management/services/gateway-management.service';

@Component({
    selector: 'error-modal',
    templateUrl: './error-modal.component.html',
    styleUrl: './error-modal.component.scss'
})
export class ErrorModalComponent {
    @Input() isModalVisible: boolean = false;   // Control dialog visibility
    @Input() modalType: string = 'normal';   // Control modal dialog type
    @Input() errorMessage: string = '';  // Error message to display
    @Output() onClose = new EventEmitter<any>();  // Event to notify when dialog is closed
    @Output() onTryAgain = new EventEmitter<any>();  // Event to notify when user tries the action again.
    @Input() organisation: Organisation;
    @Input() gatewayName: string;
    logErrrorMessage: string = '';
    isLoading: boolean = false;
    responseData: any = null;
    @Input() title: string = 'Update Failed';

    constructor(private gatewayManagementService: GatewayManagementService) {
    }

    closeDialog() {
        this.responseData = null;
        this.logErrrorMessage = '';
        this.onClose.emit();
    }

    tryAgain() {
        this.closeDialog();
        this.onTryAgain.emit();
    }

    loadDeviceLogs() {
        this.isLoading = true;
        this.gatewayManagementService.getDeviceLogs(this.organisation, this.gatewayName, '1m').then(data => {
            this.isLoading = false;
            this.responseData = data;
        }).catch(error => {
            console.log(error);
            this.isLoading = false
            this.logErrrorMessage = `Failed to get logs for ${this.gatewayName}`;
        })
    }
}
