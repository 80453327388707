import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { HomeComponent } from './components/home/home.component';
import { AuthGuard } from './security/auth.guard';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PermissionType } from './shared/entities/permissions';

const routes: Routes = [
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    {
        path: '', component: HomeComponent, canActivate: [MsalGuard], children: [
            { path: 'dashboard', loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule) },
            { path: 'telemetry', loadChildren: () => import('./modules/data-explorer/data-explorer.module').then(m => m.DataExplorerModule), canActivate: [AuthGuard], data: { permission: PermissionType.dataexplorer } },
            { path: 'admin', loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard], data: { permission: PermissionType.admin } },
            { path: 'devicemanagement', loadChildren: () => import('./modules/device-management/device-management.module').then(m => m.DeviceManagementModule), canActivate: [AuthGuard], data: { permission: [PermissionType.firmwareupdate, PermissionType.parametermanagement] } },
            { path: 'gatewaymanagement', loadChildren: () => import('./modules/gateway-management/gateway-management.module').then(m => m.GatewayManagementModule), canActivate: [AuthGuard], data: { permission: PermissionType.devicecontrol } },
            { path: 'notificationconfiguration', loadChildren: () => import('./modules/notification-management/notification-management.module').then(m => m.NotificationManagementModule), canActivate: [AuthGuard], data: { permission: PermissionType.notifications } },
            { path: 'battery', loadChildren: () => import('./modules/battery/battery.module').then(m => m.BatteryModule) },
            { path: 'module', loadChildren: () => import('./modules/module/module.module').then(m => m.ModuleModule) },
            { path: 'degradation', loadChildren: () => import('./modules/cell-degradation/cell-degradation.module').then(m => m.CellDegradationModule), canActivate: [AuthGuard], data: { permission: PermissionType.capacityprediction } },
            { path: 'error/:type', component: NotFoundComponent },
        ]
    },
    { path: '**', redirectTo: '/error/notfound' },

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
