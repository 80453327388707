@if(loaderType === "Brill-loader") {
<div class="loader"
     *ngIf="isLoading">
    <div class="loader-background"></div>
    <img src="../../../../assets/images/logo/brill-windmill-logo.svg"
         class="loader-logo">
</div>
}

@if(loaderType === "Moving-Brill-loader") {
<div class="loader"
     *ngIf="isVisible">
    <div class="loader-background"></div>
    <div class="moving-loader-wrapper">
        <img src="../../../../assets/images/logo/brill-windmill-logo.svg"
             class="moving-loader-logo"
             [ngClass]="{'vanish': !isLoading}">
    </div>

</div>
}