import { DecimalPipe } from "@angular/common";
import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "kilomega" })
export class KiloMegaPipe implements PipeTransform {
    static levels = [
        { "key": "k", value: 1000 },
        { "key": "M", value: 1000000 },
        { "key": "G", value: 1000000000 },
        { "key": "T", value: 1000000000000 },
    ];

    static defaultDigitsInfo = "1.0-0";

    private numberPipe: DecimalPipe;

    constructor(@Inject(LOCALE_ID) locale: string) {
        this.numberPipe = new DecimalPipe(locale);
    }

    transform(value: number, digitsInfo?: string) {
        if (isNaN(value)) {
            return null;
        }

        if (value === null || value === 0) {
            return value;
        }

        let abs = Math.abs(value);
        const negative = value < 0;
        let result = abs;
        let key = "";
        for (let level of KiloMegaPipe.levels) {
            let scaled = abs / level.value;
            if (scaled < 1) {
                break;
            } else {
                result = scaled;
                key = level.key;
            }
        }

        return (negative ? "-" : "") + this.numberPipe.transform(result, key === "" ? KiloMegaPipe.defaultDigitsInfo : digitsInfo) + key;
    }
}