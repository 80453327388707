import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
    errorType: string;

    constructor(private route: ActivatedRoute) { }

    ngOnInit() {
        this.errorType = this.route.snapshot.paramMap.get('type');
    }
}
