@if(errorType === 'notfound') {
<div class="content">
    <div class="error-message">
        <h1 class="message-title">Page Not Found</h1>
        <p class="message-text">
            We're sorry, the page you were looking for could not be found.
        </p>
    </div>
    <div class="btn-content">
        <a routerLink="/dashboard"
           target="_self"
           class="btn"></a>
    </div>
</div>
} @else if (errorType === 'unauthorized') {
<div class="content"
     *ngIf="errorType === 'unauthorized'">
    <div class="error-message">
        <h1 class="message-title">No access</h1>
        <p class="message-text">
            We're sorry, you currently don't have access to view this resource.
        </p>
    </div>
    <div class="btn-content">
        <a routerLink="/dashboard"
           target="_self"
           class="btn"></a>
    </div>
</div>
}