import { Component, OnInit, HostListener } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { NotificationSignalRService } from 'src/app/modules/data-explorer/services/signalr.service';
import { UserAuthorityService } from 'src/app/security/user-authority.service';
import { ThemeService } from 'src/app/theme/theme.service';
import { environment } from 'src/environments/environment';
import { Notification } from '../../entities/notification';
import { getBrillColor } from 'src/app/theme/colors';
import { PermissionType } from '../../entities/permissions';

@Component({
    selector: 'navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    menuItems: MenuItem[] = [];
    private _themeMode: string = '';

    get themeMode(): string {
        return this._themeMode;
    }

    set themeMode(value: string) {
        this._themeMode = value;
    }
    notificationSubscription: Subscription;
    routeItemsAdded: boolean = false;
    // Still need to find a way to add permission to this.
    routeMenuItems: MenuItem[] = [
        { label: 'Dashboard', routerLink: 'dashboard', icon: 'pi pi-fw pi-th-large' },
        { label: 'Data Explorer', routerLink: 'telemetry', icon: 'pi pi-fw pi-chart-bar' },
        { label: 'Degradation', routerLink: 'degradation', icon: 'pi pi-fw pi-sort-amount-down', id: PermissionType.capacityprediction },
        { label: 'Users', routerLink: 'admin', icon: 'pi pi-fw pi-users', id: PermissionType.admin },
        { label: 'Device Management', routerLink: 'devicemanagement', icon: 'pi pi-fw pi-wrench', id: PermissionType.devicecontrol },
        { label: 'Gateway Configuration', routerLink: 'gatewaymanagement', icon: 'pi pi-fw pi-cog', id: PermissionType.parametermanagement },
    ];

    filteredMenuItems: MenuItem[] = [];
    areNotificationsEnabled: boolean = false;
    constructor(private userAuthorityService: UserAuthorityService,
        private notificationSignalRService: NotificationSignalRService,
        private themeService: ThemeService, private authService: MsalService, private translateService: TranslateService) { }

    ngOnInit(): void {
        /**
         * set default language and preffered theme mode
        **/
        const theme = localStorage.getItem('Preferred-theme');
        const lang = localStorage.getItem('Preferred-lang');
        this.areNotificationsEnabled = localStorage.getItem('enable-notifications') === 'true';
        if (theme) {
            this.themeService.setTheme(theme);
            theme === 'light' ? this.themeMode = 'Dark' : this.themeMode = 'Light';
        } else {
            this.themeMode = 'Dark';
        }
        this.selectLanguage(lang || 'en-US');
        this.loadMenuItems();
        this.loadPermittedMenuItems();
        this.startOrStopNotificationService();
    }

    async startOrStopNotificationService() {
        if (this.areNotificationsEnabled && Notification.permission === 'granted') {
            await this.notificationSignalRService.start();
            this.notificationSubscription = this.notificationSignalRService.addListener().subscribe({
                next: (summary: Notification) => {
                    new Notification(summary.title, { body: summary.message, icon: 'assets/images/logo/brill-windmill-logo.svg' });
                }
            });
        } else {
            if (this.notificationSubscription) {
                this.notificationSubscription.unsubscribe();
                this.notificationSubscription = null;
                await this.notificationSignalRService.stop();
            }
        }
        this.loadMenuItems();
    }

    async checkPermission(permission: string): Promise<boolean> {
        const isValid = await this.userAuthorityService.hasPermission(permission);
        return isValid
    }

    getBorderColorForNotification(index: number) {
        return getBrillColor(index)
    }

    loadMenuItems() {
        this.menuItems = [
            {
                label: '',
                style: { 'font-size': '13px', 'padding': '5px' },
                icon: 'pi pi-fw pi-bars',
                items: [
                    {
                        label: 'Language',
                        items: [
                            {
                                label: 'English',
                                command: () => this.selectLanguage('en-US')
                            }, {
                                label: 'Español',
                                command: () => this.selectLanguage('es-ES')
                            }, {
                                label: 'Français',
                                command: () => this.selectLanguage('fr-FR')
                            }, {
                                label: 'Українська',
                                command: () => this.selectLanguage('uk-UA')
                            }, {
                                label: '日本語',
                                command: () => this.selectLanguage('ja-JP')
                            }, {
                                label: '繁體中文',
                                command: () => this.selectLanguage('zh-TW')
                            }
                        ]
                    },
                    {
                        label: 'Notifications' + (this.areNotificationsEnabled ? ' ✓' : ''),
                        styleClass: 'p-ml-6',
                        //icon: 'pi pi-bell',
                        command: async () => {
                            if (Notification.permission !== 'granted') {
                                const permission = await Notification.requestPermission();
                                if (permission === 'granted') {
                                    this.areNotificationsEnabled = true;
                                }
                            } else {
                                this.areNotificationsEnabled = !this.areNotificationsEnabled;
                            }
                            localStorage.setItem('enable-notifications', this.areNotificationsEnabled ? 'true' : 'false');
                            this.startOrStopNotificationService();
                        },
                    },
                    {
                        label: 'Theme',
                        items: [
                            {
                                label: `${this.themeMode} Mode`,
                                command: () => this.toggleThemeMode()
                            },
                        ]
                    },
                    {
                        label: `Logout`,
                        command: () => this.logOut(),
                    }
                ]
            },
        ];
    }

    @HostListener('window:resize', ['$event'])
    onScreenResize() {
        if (window.innerWidth <= 960 && !this.routeItemsAdded) {
            this.loadMenuItems();
            this.menuItems = [...this.filteredMenuItems, ...this.menuItems];
            this.routeItemsAdded = true;
        } else if (window.innerWidth > 960) {
            this.loadMenuItems();
            this.routeItemsAdded = false;
        }
    }

    async loadPermittedMenuItems() {
        const filteredItems = await Promise.all(this.routeMenuItems.map(async (item) => {
            if (item?.id) {
                const hasPermission = await this.checkPermission(item.id);
                return hasPermission ? item : null;
            } else {
                return item;
            }
        }));
        this.filteredMenuItems = filteredItems.filter((item) => item !== null);
        this.onScreenResize();
    }


    selectLanguage(language: string) {
        this.translateService.use(language);
        localStorage.setItem('Preferred-lang', language);
    }

    logOut() {
        localStorage.clear();
        this.startOrStopNotificationService();
        this.authService.logoutRedirect({ postLogoutRedirectUri: environment.postLogoutUrl });
    }

    toggleThemeMode() {
        const selectedTheme = this.themeMode === 'Dark' ? 'dark' : 'light';
        this.themeService.setTheme(selectedTheme);
        this.themeMode = this.themeMode === 'Light' ? 'Dark' : 'Light';
        this.routeItemsAdded = false;
        this.onScreenResize();
    }
}
