import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { UserAuthorityService } from './user-authority.service';

/**
 * General authguard that checks for the permission so as to give view/access to user.
 */
export const AuthGuard: CanActivateFn = async (activatedRoute: ActivatedRouteSnapshot) => {
    const route = inject(Router);
    const userAuthorityService = inject(UserAuthorityService);
    const routePermission = activatedRoute.data['permission'];
    let permissionExist: boolean = false;

    if (Array.isArray(routePermission)) {
        const awaitedPermissions = await userAuthorityService.getPermissions();
        permissionExist = routePermission.some(perms => awaitedPermissions.has(perms))
    }
    else {
        permissionExist = await userAuthorityService.hasPermission(routePermission);
    }

    if (permissionExist) {
        return true;
    }

    route.navigate(['/error/unauthorized'])
    return false
};
