import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'brill-loader',
    templateUrl: './loader.component.html',
    styleUrl: './loader.component.scss'
})
export class LoaderComponent implements OnChanges {
    @Input() isLoading: boolean = false;
    @Input() loaderType: string = "Brill-loader";
    isVisible: boolean = false;

    ngOnChanges(changes: SimpleChanges) {
        if (changes['isLoading']) {
            if (this.isLoading) {
                this.isVisible = true;
            } else {
                setTimeout(() => {
                    this.isVisible = false;
                }, 500); // Time for the "moveOut" animation to complete
            }
        }
    }
}
