import { Injectable } from '@angular/core';
import { GlobalUrl } from '../global-url';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserAuthorityService {
    permissions: string[] = [];
    allPermissions = new Map<string, boolean>();
    permissionsPromise: Promise<void>;
    constructor(private http: HttpClient, private url: GlobalUrl) { }

    private get<T>(partialUrl: string): Promise<T> {
        return firstValueFrom(this.http.get<T>(this.url.baseUrl + partialUrl));
    }

    setPermissions() {
        this.permissionsPromise = this.get<string[]>(`auth`).then(perm => {
            this.allPermissions = new Map<string, boolean>(perm.map(p => [p, true]));
        })
    }

    async hasPermission(permission: string): Promise<boolean> {
        await this.permissionsPromise;
        return this.allPermissions.has(permission);
    }

    async getPermissions(): Promise<Map<string, boolean>> {
        await this.permissionsPromise;
        return this.allPermissions;;
    }
}
