import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { SharedModule } from './shared/shared.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser'
import { registerLocaleData } from '@angular/common';
import localeGb from '@angular/common/locales/en-GB';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { HighchartsChartModule } from 'highcharts-angular';
import { NotFoundComponent } from './components/not-found/not-found.component';

registerLocaleData(localeGb);

const _isIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ") > -1;
    const msie11 = ua.indexOf("Trident/") > -1;
    return msie || msie11;
};

// This loads the neccessary json when we change the language
function httpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}

// This sets a defaults lang(stored lang or en-US) that is available before the application loads.
function appInitializerFactory(translate: TranslateService) {
    return () => {
        const lang = localStorage.getItem('Preferred-lang');
        translate.use(lang ?? 'en-US')
        translate.setDefaultLang('en-US');
    };
}


@NgModule({
    declarations: [
        AppComponent,
        NotFoundComponent,
        HomeComponent
    ],
    imports: [
        BrowserModule,
        SharedModule,
        HttpClientModule,
        HighchartsChartModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en-US',
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        BrowserAnimationsModule,
        AppRoutingModule,
        MsalModule.forRoot(new PublicClientApplication(
            {
                auth: {
                    clientId: environment.clientID,
                    redirectUri: environment.portalUrl,
                    authority: 'https://login.microsoftonline.com/common'
                },
                cache: {
                    cacheLocation: 'localStorage',
                    storeAuthStateInCookie: _isIE()
                }
            }
        ), {
            interactionType: InteractionType.Redirect,
            authRequest: {
                scopes: [environment.apiScope]
            }
        }, {
            interactionType: InteractionType.Redirect,
            protectedResourceMap: new Map(
                [
                    [environment.portalUrl, [environment.apiScope]],
                    [environment.mulanApi, [environment.apiScope]],
                    [environment.badmintonApi, [environment.apiScope]]
                ]
            )
        })
    ],
    providers: [ConfirmationService, MessageService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: LOCALE_ID,
            useValue: "en-GB"
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService],
            multi: true
        },
        MsalGuard],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
