export interface LatAndLong {
    lat: number,
    lng: number,
    draggable: boolean
}

export enum ShowMap {
    show = 'SHOW',
    hide = 'HIDE',
    noLocation = 'NO LOCATION'
}