import { Component, Input } from '@angular/core';

@Component({
    selector: 'notification-events',
    templateUrl: './notification-events.component.html',
    styleUrl: './notification-events.component.scss'
})
export class NotificationEventsComponent {
    @Input() isNotificationEventDataLoading = true;
    @Input() notificationEventLoadingStatus: string = 'Loading...';
    @Input() notificationEventsData: any = {};
    @Input() maxHeight: string = '';
}
