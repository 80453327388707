@if(isChart) {
<section class="chart-div"
         [style.height]="height">
    <div id="title">{{title | translate}}</div>
    <p class="chart-loadingState message">
        {{message | translate}}
    </p>
</section>
} @else {
<section>
    <p class="loadingState message">
        {{message | translate}}
    </p>
</section>
}