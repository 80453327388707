import { Component, Input } from '@angular/core';

@Component({
    selector: 'no-data-found',
    templateUrl: './no-data-found.component.html',
    styleUrl: './no-data-found.component.scss'
})
export class NoDataFoundComponent {

    @Input() title: string = '';
    @Input() message: string = '';
    @Input() isChart: boolean;
    @Input() height: string = '';
}
