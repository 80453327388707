import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventType } from '@azure/msal-browser';
import { Subscription } from 'rxjs';
import { UserAuthorityService } from './security/user-authority.service';
import { closeSubscriptions } from './shared/utilities/close-subscription';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    protected allSubscriptions: Subscription[] = [];
    constructor(private broadcastService: MsalBroadcastService, private msalService: MsalService, private userAuthorityService: UserAuthorityService) {

    }

    ngOnInit() {
        this.allSubscriptions.push(this.broadcastService.msalSubject$.subscribe({
            next: (msalSubject) => {
                if (msalSubject.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
                    this.msalService.loginRedirect();
                }
            },
        }));

        this.userAuthorityService.setPermissions();
    }

    ngOnDestroy(): void {
        closeSubscriptions(this.allSubscriptions);
    }
}
