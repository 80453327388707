import { LatAndLong } from "../components/map/latandlong";

// https://stackoverflow.com/questions/37523872/converting-coordinates-from-epsg-3857-to-4326
// Convert EPSG 3857 to 4326
export function coord3857To4326(coord: LatAndLong) {
    const e_value = 2.7182818284;
    const X = 20037508.34;
    const lat3857 = coord.lat
    const long3857 = coord.lng;
    //converting the longitude from epsg 3857 to 4326
    const long4326 = (long3857 * 180) / X;
    //converting the latitude from epsg 3857 to 4326 split in multiple lines for readability        
    let lat4326 = lat3857 / (X / 180);
    const exponent = (Math.PI / 180) * lat4326;
    lat4326 = Math.atan(Math.pow(e_value, exponent));
    lat4326 = lat4326 / (Math.PI / 360); // Here is the fixed line
    lat4326 = lat4326 - 90;
    return { lat: lat4326, lng: long4326, draggable: coord.draggable };
}


// Convert EPSG 4326 to 3857
export function coord4326To3857(coord) {
    const X = 20037508.34;
    let long3857 = (coord.lng * X) / 180;
    let lat3857 = parseFloat(coord.lat) + 90;
    lat3857 = lat3857 * (Math.PI / 360);
    lat3857 = Math.tan(lat3857);
    lat3857 = Math.log(lat3857);
    lat3857 = lat3857 / (Math.PI / 180);
    lat3857 = (lat3857 * X) / 180;
    return { lat: lat3857, lng: long3857, draggable: coord.draggable };
}