import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { Table } from 'primeng/table';
import { saveAs } from 'file-saver';
import { SortEvent } from 'primeng/api';
import { TableColumn } from './tablecolumn';
import { UserAuthorityService } from 'src/app/security/user-authority.service';

@Component({
    selector: 'table-component',
    templateUrl: './table-view.component.html',
    styleUrls: ['./table-view.component.scss']
})
export class TableViewComponent implements OnInit {

    _columns: TableColumn[] = [];
    _permittedColumns: TableColumn[] = [];
    get columns(): TableColumn[] {
        return this._columns;
    }
    @Input() set columns(value: TableColumn[]) {
        this._columns = value;
        this.updatePermittedColumns();
    }
    get permittedColumns(): TableColumn[] {
        return this._permittedColumns;
    }
    @Input() tableData = [];
    @Input() addLabel: string = '';
    @Input() icon: string = '';
    @Input() searchPlaceholder: string = '';
    @Input() scrollHeight: string = '300px';
    @Input() scrollable: boolean = false;
    @Input() tableHeaderName: string = '';
    @Input() showSearchBar = true
    @Input() showActionColumn = true;
    @Input() showTableHeaderName = false;
    @Input() enableClickRowToRoute = false;
    @Input() downloadData = false;
    @Output() selectRows = new EventEmitter<any>();
    @Output() selectAllRows = new EventEmitter<any>();
    @Input() showTelemetryRouteButton = false;
    searchCharacter: any;
    @Input() selectedData: any = [];
    @Input() selectAll: boolean = false;
    @Input() uniqueIdentifier: string = '';
    @Input() showCheckbox = false;
    @Input() routeParam = { route: '', props: [], keys: [] };
    @ViewChild('dt') table: Table;
    @Input() tableLoadingState = 'Loading...'
    @Input() toolbarRight: TemplateRef<any>;
    @Output() colorForRow = new EventEmitter<RowColor>();
    @Output() colourForCell = new EventEmitter<CellColour>();

    constructor(private userAuthorityService: UserAuthorityService) { }

    private async updatePermittedColumns() {
        var cols: TableColumn[] = [];
        for (var col of this._columns) {
            if (!col.permission) {
                cols.push(col);
            } else if (await this.userAuthorityService.hasPermission(col.permission)) {
                cols.push(col);
            }
        }
        this._permittedColumns = cols;
    }

    ngOnInit(): void {
    }

    onSelectionChange(value = []) {
        this.selectedData = value;
        this.selectRows.emit(this.selectedData)
    }

    onSelectAllChange(event: any) {
        const checked = event.checked;

        if (checked) {
            this.selectedData = this.tableData;
            this.selectAll = true;
        }
        else {
            this.selectedData = [];
            this.selectAll = false;
        }
        this.selectAllRows.emit(this.selectedData)
    }

    isChecked(value: any) {
        return Boolean(value);
    }

    // Checkbox when clicked should stop progating so that other events (e.g. row click) is cancelled.
    onClickCheckbox(event: Event) {
        event.preventDefault();
        event.stopPropagation();
    }
    // Search function for table, this is static.
    applyFilterGlobal($event, stringVal) {
        this.table.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
    }

    exportExcel() {
        import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.tableData);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, this.tableHeaderName);
        });
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }

    createQueryParams(rowData, keys: string[], value: string[]) {
        const result = keys.reduce((acc, key, index) => {
            acc[key] = rowData[value[index]];
            return acc;
        }, {});
        return result
    }

    getCircleColor(row: any) {
        var rowColor = new RowColor();
        rowColor.row = row;
        this.colorForRow.emit(rowColor);
        if (rowColor.color) {
            return rowColor.color;
        } else {
            return '';
        }
    }

    getCellColour(row: any, col: TableColumn) {
        var cellColour = new CellColour();
        cellColour.column = col.field;
        cellColour.row = row;
        this.colourForCell.emit(cellColour);
        if (cellColour.colour) {
            return cellColour.colour;
        } else {
            return '';
        }
    }

    // From Primeng custom sort https://primeng.org/table#sort
    customSort(event: SortEvent) {
        event.data.sort((data1, data2) => {
            let value1 = data1[event.field];
            let value2 = data2[event.field];
            let result = null;

            if (value1 == null && value2 != null) result = -1;
            else if (value1 != null && value2 == null) result = 1;
            else if (value1 == null && value2 == null) result = 0;
            else if (typeof value1 === 'string' && typeof value2 === 'string') result = value1.localeCompare(value2);
            else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

            return event.order * result;
        });
    }

    getTooltip(row: any, col: TableColumn) {
        if (col.tooltipFactory) {
            return col.tooltipFactory(row);
        }
        return null;
    }
}

export class RowColor {
    row: any
    color: string
}

export class CellColour {
    row: any
    column: string
    colour: string
}