const BrillColorsLightnessWise = ['#E2BF3A', '#E9CC64', '#EFD98E', '#F6E6B7', '#36AE78', '#61C095', '#8DD1B2', '#B8E3CF', '#EE7748', '#F2946E', '#F7B194', '#FBCDB9', '#D34563', '#DD6D83', '#E694A4', '#F0BCC4']

// This is used to generate necessary brill colors to the graphs, specifically for line charts.
export function getBrillColor(index) {
    return BrillColorsLightnessWise[index];
}

export function getColorByRootName(color: string) {
    const rootStyles = getComputedStyle(document.documentElement);
    return rootStyles.getPropertyValue(color);
}

export function getColorForSocStatus(status: string) {
    switch (status) {
        case 'High':
            return getColorByRootName("--brill-primary-green-color");
        case 'Medium':
            return getColorByRootName("--brill-yellow-color");
        case 'Low':
            return getColorByRootName("--brill-os-pink-color");
        default:
            return getColorByRootName("--primary-white-color");
    }
}

export function getColorForStatus(status: string) {
    switch (status) {
        case 'Off':
            return getColorByRootName("--brill-silver-color");;
        case 'Idle':
            return '#8DD1B2'
        case 'Charged':
            return getColorByRootName("--brill-primary-green-color");
        case 'Discharged':
            return getColorByRootName("--brill-os-pink-color");
        case 'Charging':
            return getColorByRootName("--brill-os-orange-color");
        case 'Discharging':
            return getColorByRootName("--brill-yellow-color");
        case 'Error':
            return getColorByRootName("--brill-black-color");
        case 'Warning':
            return getColorByRootName("--brill-mid-silver-color")
        default:
            return getColorByRootName("--primary-white-color");
    }
}