export const environment = {
    production: process.env['ENVIRONMENT'] === 'Production',
    postLogoutUrl: process.env['PORTAL_URL'],
    mulanApi: process.env['MULAN_URL'],
    badmintonApi: process.env['BADMINTON_URL'],
    clientID: process.env['AAD_CLIENT_ID'],
    azureMapSubscriptionKey: process.env['AZM_SUBSCRIPTION_KEY'],
    apiScope: process.env['AAD_API_SCOPE'],
    portalUrl: process.env['PORTAL_URL']
};
