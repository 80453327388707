<p-tree [value]="allDeviceInfoNodes"
        [selectionMode]="selectionMode"
        [(selection)]="selectedNode"
        (onNodeSelect)="onNodeSelected($event.node)"
        (onNodeUnselect)="onNodeSelectedOrDeselected($event.node)"
        [propagateSelectionUp]="false"
        [propagateSelectionDown]="false"
        class="w-full md:w-30rem"
        [filter]="true"
        filterMode="strict"
        filterPlaceholder="Search...">
    <ng-template let-node
                 pTemplate="default">
        <span [pTooltip]="getTooltipText(node)">{{node.label}}</span>
    </ng-template>
</p-tree>