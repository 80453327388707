import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { GlobalUrl } from 'src/app/global-url';
import { Organisation } from '../../admin/entities/api/organisation';
import { ComponentVersion } from '../entities/api/componentversion';
import { GatewayComponentUpdateResponse } from '../entities/api/gatewaycomponentupdateresponse';
import { GetCanBusesResponse } from '../entities/api/getcanbussesinterface';
import { ResetCanBusResponse } from '../entities/api/resetcanbusresponse';

@Injectable({
    providedIn: 'root'
})
export class GatewayManagementService {

    constructor(private http: HttpClient,
        private url: GlobalUrl) { }

    getDeviceConfiguration(organisation: Organisation, deviceName: string): Promise<any> {
        return firstValueFrom(this.http.get<any>(this.url.badmintonUrl + `organisations/${organisation.domain}/brillos/config/${deviceName}`, {}));
    }

    updateDeviceConfiguration(organisation: Organisation, deviceName: string, updatedTwin: string): Promise<any> {
        return firstValueFrom(this.http.put<any>(this.url.badmintonUrl + `organisations/${organisation.domain}/brillos/config/${deviceName}`, updatedTwin));
    }

    getDeviceLogs(organisation: Organisation, deviceName: string, since: string): Promise<any> {
        return firstValueFrom(this.http.get<any>(this.url.badmintonUrl + `organisations/${organisation.domain}/brillos/logs/${deviceName}/${since}`, { responseType: 'text' as 'json' }));
    }

    restartGateway(organisation: Organisation, deviceName: string) {
        return firstValueFrom(this.http.put<any>(this.url.badmintonUrl + `organisations/${organisation.domain}/brillos/control/${deviceName}/restart`, {}));
    }

    getGatewayComponentVersion(organisation: Organisation, componentName: string): Promise<string[]> {
        return firstValueFrom(this.http.get<string[]>(this.url.badmintonUrl + `organisations/${organisation.domain}/brillos/gateway/component/version/${componentName}`));
    }

    getGatewayCurrentVersions(organisation: Organisation, gatewayName: string): Promise<ComponentVersion[]> {
        return firstValueFrom(this.http.get<ComponentVersion[]>(this.url.badmintonUrl + `organisations/${organisation.domain}/brillos/gateway/version/${gatewayName}`));
    }

    resetCanBus(organisation: Organisation, deviceName: string, canBus: string) {
        return firstValueFrom(this.http.put<ResetCanBusResponse>(this.url.badmintonUrl + `organisations/${organisation.domain}/brillos/control/${deviceName}/can/${canBus}/restart`, {}));
    }

    getCanInterfaces(organisation: Organisation, gatewayName: string): Promise<GetCanBusesResponse> {
        return firstValueFrom(this.http.get<GetCanBusesResponse>(this.url.badmintonUrl + `organisations/${organisation.domain}/brillos/devices/${gatewayName}/interfaces/can`));
    }

    updateComponentWithNewVersion(organisation: Organisation, gatewayName: string, componentName: string, imagePath: string): Promise<GatewayComponentUpdateResponse> {
        return firstValueFrom(this.http.put<GatewayComponentUpdateResponse>(this.url.badmintonUrl + `organisations/${organisation.domain}/brillos/update/gateway/component/${gatewayName}/${componentName}`, { imagePath }));
    }
}
