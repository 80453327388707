import { Subject, Subscription } from "rxjs";

function closeSub<T>(sub: Subject<T> | Subscription) {
    sub && !sub.closed && sub.unsubscribe && sub.unsubscribe();
}

// This is used to unsubscribe all the subscribe observables, basically destroying all subscriptions.
// Its combined with the above functin closeSub...
export function closeSubscriptions<T>(subs: Subject<T> | Subscription | Subscription[]) {
    if (!subs) return;

    if (Array.isArray(subs))
        subs.forEach(s => closeSub(s));
    else
        closeSub(subs);
    subs = null;
}