import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    private currentTheme = 'light'; // Default theme


    constructor(@Inject(DOCUMENT) private document: Document) { }

    setTheme(theme: string) {
        this.currentTheme = theme;
        const logoElement = document.querySelectorAll('#brillpower-logo');
        let themeLink = this.document.getElementById('app-theme') as HTMLLinkElement;
        logoElement.forEach(x => {
            if (logoElement) theme === 'dark' ? x.setAttribute('src', 'assets/images/logo/brill-analytics-dark-logo.svg') : x.setAttribute('src', 'assets/images/logo/brill-analytics-white-logo.svg');
        })
        if (themeLink) {
            if (this.currentTheme === 'dark') {
                themeLink.href = 'md-dark-indigo.css'
            } else {
                themeLink.href = 'md-light-indigo.css'
            }
        }
        localStorage.setItem('Preferred-theme', theme);
    }
}
